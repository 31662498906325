import React from 'react';
import pseudosaurusImg from '../assets/pseudosaurus.png';

function Header() {
  return (
    <div className="header">
      <img src={pseudosaurusImg} alt="Pseudosaurus" width="150" className="hero-image" />
      <h1>Pseudosaurus</h1>
      <p>Twitch streamer</p>
    </div>
  )
}

export default Header;
