import React from "react";
import SingleLink from "./singleLink";

const links = [
  {
    link: "https://www.twitch.tv/pseudosaurus",
    title: "Twitch",
    icon: ["fab", "twitch"],
  },
  {
    link: "https://www.streamlabs.com/pseudosaurus/tip",
    title: "Tip me (StreamLabs)",
    icon: ["fas", "dollar-sign"],
  },
  {
    link: "https://www.youtube.com/@pseudosaurus",
    title: "YouTube",
    icon: ["fab", "youtube"],
  },
  {
    link: "https://discord.gg/TqhcpSM",
    title: "Join the Discord!",
    icon: ["fab", "discord"],
  },
  {
    link: "https://tiktok.com/@pseudosaurus_",
    title: "TikTok",
    icon: ["fab", "twitter"],
  },
];

const LinkList = ({ isLive }) => {
  return links.map((link) => {
    return (
      <SingleLink
        link={link.link}
        title={link.title}
        icon={link.icon}
        altText={link.altText}
        isLive={isLive}
      />
    );
  });
};

export default LinkList;
